import { ENUMS } from '../enums';

export const PAYMENT = [
	{
		key: 'payeeNameEnc',
		description: "Payee's Name (Maximum Plaintext 30 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payeePanEnc',
		description: "Payee's Card Number (Maximum Plaintext 19 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerAccountEnc',
		description: "Payer's Account Number (Maximum Plaintext 19 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerIdNoEnc',
		description: "Payer's ID Number (Maximum Plaintext 20 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerExtCardId',
		description: "Payer's Card Mapping ID (Unique to Every Card)",
		example: '',
		type: 'String',
	},
	{
		key: 'payerIdType',
		description: "Payer's Document Type",
		example: '',
		enum: ENUMS.documentTypeEnum,
		type: 'String',
	},
	{
		key: 'payerNameEnc',
		description: "Payer's Name (Maximum Plaintext 30 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerNationalityCode',
		description: "Payer's Nationality Code (Fixed Length 3)",
		example: '',
		type: 'String',
	},
	{
		key: 'permanentAddress',
		description: "Payer's Address",
		example: '',
		type: 'String',
	},
	{
		key: 'permanentCityCode',
		description: "Payer's City Code",
		example: '',
		type: 'String',
	},
	
	{
		key: 'permanentCountryCode',
		description: "Payer's Country Code",
		example: '',
		type: 'String',
	},
];

export const JIANDANDIAN = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',
		'1104',

		// physical card
		'1201',
		'1202',
		'1203',
		'1204S',

		// verification
		'2101',

		// query cardholder info
		'2211',
		'2221',
		'2232',
		'2233',
		'2234',
		'2241',
		'2242',

		// modify cardholder info

		// card configuration
		'3101',
		'3103',
		'3104',
		'3105',
		'3106',
		'3107',
		'3108',

		// internal transfer
		'4101',
		'4102',

		// bakong transaction
		'4212',
		'4223',

		// umps
		// umts
		'4403',
		'4405',
		'N204',

		// others
		'N3',
		'N203',

		// notificaiton
		'NTF1',
		'NTF2',
		'NTF3',
		'NTF4',
		'NTF5',
		'NTF6',
		'NTF7',
		'NTF8',
		'NTF9',
		'NTF10',
		'NTF11',
		'NTF12',
		'NTF13',
		'NTF14',
		// 'yourNotificationUrl',
	],
};

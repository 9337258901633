export const MOBILE = [
	{
		key: 'mobile',
		description: '手机号 [最大长度30, 格式: 区号 + "-" + 电话号码]',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'mobileOUT',
		description: '转出手机号 [最大长度30, 格式: 区号 + "-" + 电话号码]',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'mobileIN',
		description: '转入手机号 [最大长度30, 格式: 区号 + "-" + 电话号码]',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'tel',
		description: '固定电话',
		example: '',
		type: 'String',
	},
	{
		key: 'phone',
		description: '手机号 [最大长度30, 格式: 区号 + "-" + 电话号码]',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'mobileNo',
		description: '手机号 [最大长度30, 格式: 区号 + "-" + 电话号码]',
		example: '855-23567567',
		type: 'String',
	},
];
